import React, { useEffect, useState, useRef } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import config from '../../config.json';
import { translationContext } from '../../App';
import { urlParamsContext } from '../../App';

export default function LocationSearchInput() {
  const dictionary = React.useContext(translationContext);
  const urlParams = React.useContext(urlParamsContext);
  const [searchLabel, setSearchLabel] = useState();
  const [selectedValue, setSelectedValue] = useState(null); // Controlled state for selected value
  const inputElement = useRef();


  useEffect(() => {
    const tempUrlParams = new URLSearchParams(window.location.hash.split('?')[1]);
    const locationName = tempUrlParams.get('search');

    if (locationName) {
      setSelectedValue({ label: locationName, value: locationName });
  
      // Perform geocoding to fetch lat/lng and update urlParams
      geocodeByAddress(locationName)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          urlParams.setSearch(locationName);
          urlParams.setLatitude(lat);
          urlParams.setLongitude(lng);
          urlParams.setLocationSelected(true);
        })
        .catch((error) => {
          console.error('Error fetching geocoded location on mount:', error);
          urlParams.setShowError(true);
        });
    }
  }, []);
  
  useEffect(() => {
    const searchText = dictionary?.translations?.find((x) => x.code === 'Search-Results-bar')?.text;
    if (searchText) {
      setSearchLabel(searchText);
    }
  }, [dictionary?.versionCounter]);

  const handlePlaceSelected = (place) => {
    console.log('Place selected:', place);

    if (!place || !place.label) {
      urlParams.setShowError(true);
      return;
    }

    const address = place.label;

    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        urlParams.setSearch(address);
        urlParams.setLatitude(lat);
        urlParams.setLongitude(lng);
        urlParams.setLocationSelected(true);
        setSelectedValue(place); // Update controlled state with the selected place
      })
      .catch((error) => {
        console.error('Error fetching latitude and longitude via geocode:', error);
        urlParams.setShowError(true);
      });
  };

  const handleFocus = () => {
    setSelectedValue(null);
    urlParams.setShowError(false);

  };

  return (
    <div className={`results-list-search-options-search-bar ${urlParams.showError ? 'error' : ''}`}>
      <GooglePlacesAutocomplete
        withSessionToken={true}
        selectProps={{
          ref:inputElement,
          value: selectedValue, 
          onChange: handlePlaceSelected, 
          onFocus: handleFocus,
          placeholder: searchLabel,
          openMenuOnClick: false,
          styles: {
            input: (provided) => ({
              ...provided,
              
                        }),
            control: (provided, state) => ({
              ...provided,
              border: '1px solid #393939',
              borderRadius: '0.5em',
              paddingTop:'0.5em',
              paddingBottom:'0.5em',
              boxShadow: state.isFocused ? '0 0 0 2px #000' : 'none',
              '&:hover': {
                borderColor: '#000',
              },
              color: '#393939',
              fontSize: '0.8em',
              lineHeight: '1.2em',
            }),
            menu: (provided) => ({
              ...provided,
              marginTop: '3px', 

            }),
            dropdownIndicator: () => ({ display: 'none' }), 

          },
          
        }}
        autocompletionRequest={{
          types: ['geocode'],
          componentRestrictions: { country: 'us' },
        }}
      />
            {urlParams.showError && (
        <p className="results-list-search-error">
          {dictionary?.translations?.find((x) => x.code === 'Valid-Location')?.text}
        </p>
      )}
      <button
        className="results-list-search-submit"
        onMouseDown={(e) => e.preventDefault()}
        disabled={!selectedValue}
        onClick={() => {
          if (selectedValue) {
            console.log('Search initiated for:', selectedValue.label);
          } else {
            urlParams.setShowError(true);
          }
        }}
      >
        <span className="material-symbols-rounded">search</span>
      </button>

    </div>
  );
}
