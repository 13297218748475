export const trackNumberOfSearchInSession = () => {
  let currentSearches = sessionStorage.getItem('numberOfSearches');
  let count = currentSearches ? parseInt(currentSearches, 10) : 0;
  count++;
  sessionStorage.setItem('numberOfSearches', count.toString());
  return count;
};

export const trackNumberOfCallClicksInSession = () => {
  let currentCalls = sessionStorage.getItem('numberOfCallClicks');
  let count = currentCalls ? parseInt(currentCalls, 10) : 0;
  count++;
  sessionStorage.setItem('numberOfCallClicks', count.toString());
  return count;
};
export const trackNumberOfHotelViewedInSession = () => {
  let currentHotelsViewed = sessionStorage.getItem('numberOfHotelViewed');
  let count = currentHotelsViewed ? parseInt(currentHotelsViewed, 10) : 0;
  count++;
  sessionStorage.setItem('numberOfHotelViewed', count.toString());
  return count;
};
export const trackSearchResponseEvent = (
  userLanguage,
  locationEntered,
  lat,
  long,
  radius,
  filters,
  numberOfResults,
  numberOfFilterdResults,
  numberOfSearches
) => {
  if (window.gtag) {
    window.gtag('event', 'tsa_search_response', {
      tsa_user_language: userLanguage,
      tsa_number_of_searches: numberOfSearches,
      tsa_location_entered: locationEntered,
      'tsa_lat_long': lat + ', ' + long,
      tsa_radius: radius,
      tsa_pets_allowed: filters.petsAllowed,
      tsa_ada_accomodations: filters.adaAccomodations,
      tsa_meals_provided: filters.mealsProvided,
      tsa_number_of_results: numberOfResults,
      tsa_number_of_filtered_results: numberOfFilterdResults,
      'tsa_filteredResults_totalResults': numberOfFilterdResults + ', ' + numberOfResults,
      // How many searches did the user conduct before clicking on the first hotel?
    });
  }
};

export const trackHotelView = (
  language,
  hotelId,
  hotelName,
  hasPhotos,
  searchResultsRank, // depends on whether they are coming from list or map
  distance,
  viewSource // either 'list' or 'map'
) => {
  if (window.gtag) {
    window.gtag('event', 'tsa_hotel_view', {
      tsa_hotel_name: hotelName,
      tsa_user_language: language,
      tsa_hotel_id: hotelId,
      tsa_has_photos: hasPhotos,
      tsa_search_results_rank: searchResultsRank,
      tsa_distance: distance,
      tsa_src_list_or_map: viewSource,
      tsa_total_hotel_viewed: trackNumberOfHotelViewedInSession(),
      tsa_total_number_of_searches: sessionStorage.getItem('numberOfSearches'),
      'tsa_hotels_viewed_searches':
        sessionStorage.getItem('numberOfHotelViewed') + ' / ' + sessionStorage.getItem('numberOfSearches'),
    });
  }
};

export const trackCallClick = (
  language,
  hotelId,
  hotelName,
  hasPhotos,
  searchResultsRank,
  distance,
  viewSource // either 'list' or 'map'
) => {
  if (window.gtag) {
    window.gtag('event', 'tsa_call_click', {
      tsa_hotel_name: hotelName,
      tsa_user_language: language,
      tsa_hotel_id: hotelId,
      tsa_has_photos: hasPhotos,
      tsa_search_results_rank: searchResultsRank,
      tsa_distance: distance,
      tsa_src_list_or_map: viewSource,
      tsa_total_number_called: trackNumberOfCallClicksInSession(),
      tsa_total_number_of_searches: sessionStorage.getItem('numberOfSearches'),
      tsa_call_clicks_searches:
        sessionStorage.getItem('numberOfCallClicks') + ' / ' + sessionStorage.getItem('numberOfSearches'),
    });
  }
};

export function trackMapInteraction(action, label = '') {
  if (window.gtag) {
    window.gtag('event', 'tsa_map_interactions', {
      tsa_event_category: action,
      tsa_event_label: label,
    });
  }
}

export function trackAlternatePaths(action, label = '') {
  if (window.gtag) {
    window.gtag('event', 'tsa_alternate_Paths', {
      tsa_event_category: action,
      tsa_event_label: label,
    });
  }
}

export const trackLanguageChange = (action, label = '') => {
  if (window.gtag) {
    window.gtag('event', 'tsa_language_change', {
      tsa_change_count: action,
      tsa_language_selected: label,
    });
  }
};
