/**
 * @typedef {Object} Translations
 * @property {string} Code
 * @property {string} English
 * @property {string} Spanish
 */

/**create the translations object
 * @type {Translations}
 * */
const translations = [
  {
    Code: 'TSA',
    en: 'Transitional Sheltering Assistance (TSA)',
    es: 'Asistencia de Alojamiento Transitorio',
  },
  {
    Code: 'Eligible-1',
    en: 'If you are eligible for Transitional Sheltering Assistance, FEMA will pay the cost to stay in certain lodging providers for a limited period. Costs covered are for the cost of the room, pet fees, and taxes. Any other expenses are not included. Follow the steps above to locate participating properties to see if they have availability in the area you have selected. Since room availability changes quickly, please call the lodging provider prior to traveling to confirm they have available rooms.',
    es: 'Si usted es elegible para la Asistencia de Alojamiento Transitorio, FEMA pagará el costo de permanecer en ciertos proveedores de alojamiento durante un período limitado. Los costos cubiertos son para el costo de la habitación, cargos por mascotas y los impuestos. Cualquier otro gasto no está incluido. Siga los pasos anteriores para localizar los inmuebles participantes y comprobar si tienen disponibilidad en la zona que ha seleccionado. Debido a que la disponibilidad de habitaciones cambia rápidamente, por favor llame al proveedor de alojamiento antes de viajar para confimar que tengan habitaciones disponibles.',
  },
  {
    Code: 'Eligible-2',
    en: 'Lodging through the Transitional Sheltering Assistance program may be limited to certain geographic areas. Use your FEMA registration number to access a list of participating lodging providers.',
    es: 'El alojamiento a través del programa de Asistencia de Alojamiento Transitorio puede estar limitado a ciertas áreas geográficas. Utilice su número de registro de FEMA para acceder a una lista de proveedores de alojamiento participantes.',
  },
  { Code: 'Enter-RegNumber', en: 'Enter Your FEMA Registration Number', es: 'Introduzca su Número de Registro de FEMA' },
  { Code: 'Submit', en: 'Submit', es: 'Enviar' },
  {
    Code: 'Place-To-Stay',
    en: 'Find a room at a participating property',
    es: 'Encuentre una habitación en una propiedad participante',
  },
  { Code: 'Search-Results-bar', en: 'Search City or County or Zip', es: 'Buscar ciudad, condado o código postal' },
  { Code: 'Hotels-Within', en: 'Show Hotels Within', es: 'Mostrar hoteles' },
  { Code: 'Show-Results', en: 'Show Results', es: 'Resultados' },
  { Code: 'Filters', en: 'Filters', es: 'Filtros' },
  {
    Code: 'Show-Number-Results',
    en: 'Showing {resultsCount} of {totalCount} results',
    es: 'Mostrando {resultsCount} de {totalCount} resultados',
  }, //Will need to come back to this one sent to Translation for Client
  { Code: 'Pets-Allowed', en: 'Pets Allowed', es: 'Se admiten mascotas' },
  { Code: 'Pets-Not-Allowed', en: 'Pets Not Allowed', es: 'No se admiten mascotas' },
  { Code: 'ADA-Accommodations', en: 'ADA Accommodations', es: 'Adaptaciones de ADA para personas con discapacidades' },
  {
    Code: 'No-ADA-Accommodations',
    en: 'No ADA Accommodations',
    es: 'Sin adaptaciones de ADA para personas con discapacidades',
  },
  { Code: 'Meals-Provided', en: 'Hotel Has Kitchenettes', es: 'El hotel tiene cocina en las habitaciones' },
  { Code: 'No-Meals-Provided', en: 'No Kitchenettes', es: 'Sin cocina en las habitaciones' },
  { Code: 'Phone', en: 'Phone:', es: 'Teléfono:' },
  { Code: 'Show-Hotels-Within', en: 'Show Hotels Within (miles)', es: 'Mostrar hoteles en (millas)' },
  { Code: 'Hotel-Chain-(name)', en: 'Hotel Chain (name)', es: 'Cadena hotelera (nombre)' },
  { Code: 'Reset-All', en: 'Reset All', es: 'Restablecer todo' },
  { Code: 'To-Book-Please-Call', en: 'Call Hotel', es: 'Llamar al hotel' },
  // {Code: 'No-Hotel-In-Reg-Num', en: 'There is not a hotel for your registration code in this area. Try searching in one of these states:', es: 'No hay ningún hotel para su código de registro en esta zona. Intenta buscar en uno de estos estados:'},
  { Code: 'Select State', en: 'Select State', es: 'Seleccionar estado' },
  { Code: 'Map-View', en: 'Map View', es: 'Ver mapa' },
  { Code: 'List-View', en: 'List View', es: 'Ver lista' },
  { Code: 'Viewed', en: 'Viewed', es: 'Visto' },
  { Code: 'Called', en: 'Called', es: 'Llamado' },
  { Code: 'View-Filtered-Results', en: 'View Filtered Results', es: 'Ver resultados filtrados' },

  {
    Code: 'Request-Timed-Out',
    en: 'The request has timed out. Please try again.',
    es: 'La solicitud ha expirado. Por favor, inténtelo nuevamente.',
  },
  { Code: 'No-Hotels-Found', en: 'No hotels found.', es: 'No se han encontrado hoteles.' },
  {
    Code: 'RegNumber-Ineligible-Header',
    en: 'Something is wrong. The FEMA registration number you have entered is not linked to the Transitional Sheltering Assistance (TSA) Program.',
    es: 'Algo está incorrecto. El número de registro de FEMA que ha introducido no está vinculado al Programa de Alojamiento Transitorio (TSA).',
  },
  {
    Code: 'RegNumber-Ineligible-Body',
    en: '<p>Please:</p><ul><li>Double-check your FEMA registration number and try again.</li><li>Check your communication From FEMA (voice; text; email; online account) to confirm you are eligible for TSA</li><li>Visit a DRC.  Use the DRC Locator or text DRC and your ZIP code to 43362.</li><li>Call the FEMA Helpline at <a href="tel:+1-800-621-3662">800-621-3662</a>.</li><li>If you use a Relay Service provide that information to FEMA</li></ul>',
    es: '<p>Por favor:</p><ul><li>Verifique su número de registro de FEMA e inténtelo de nuevo.</li><li>Verifique su comunicación de FEMA (voz, texto, correo electrónico, cuenta en línea) para confirmar que usted es elegible para TSA.</li><li>Visite un Centro de Recuperación por Desastre (DRC). Utilice el localizador de DRC o envíe un mensaje de texto con la palabra CENTRO y su código postal al 43362.</li><li>Llame a la línea de ayuda de FEMA al <a href="tel:+1-800-621-3662">800-621-3662</a>.</li><li>Si utiliza un servicio de retransmisión, facilite esa información a FEMA.</li></ul>',
  },
  { Code: 'More-Details', en: 'More details about {0}', es: 'Más información sobre {0}' },
  { Code: 'Kitchen-In-Room', en: 'Kitchen in Room', es: 'Cocina en habitación' },
  {
    Code: 'Num-of-Rooms-With-ADA',
    en: '# of Rooms with ADA Accomodations',
    es: '# de habitaciones con adaptaciones de ADA',
  },
  { Code: 'Unknown', en: 'Unknown', es: 'Desconocido' },
  { Code: 'Back', en: 'Back', es: 'Volver' },
  { Code: 'Results', en: 'results', es: 'resultados' },
  { Code: 'Result', en: 'result', es: 'resultado' },
  { Code: 'Forgot-RegNumber', en: 'Forgot your FEMA registration number?', es: '¿Ha olvidado su número de registro de FEMA?' },
  {
    Code: 'Forgot-RegNumber-Detail-1',
    en: '<p class="center-forgot-text">To find your FEMA registration number:</p><ul><li>If you have set up an online account go to <a href="https://www.disasterassistance.gov/" target="_blank">DisasterAssistance.gov</a>.</li><li>Visit a Disaster Recovery Center (DRC).<ul><li>To find a DRC go to <a href="https://egateway.fema.gov/ESF6/DRCLocator" target="_blank">https://egateway.fema.gov/ESF6/DRCLocator</a>,</li></ul></li><li>Call the FEMA Helpline at <a href="tel:+1-800-621-3362">800-621-3362</a>.<ul><li>If you are using a Relay Service, please provide FEMA the number for your service.</li></ul></li></ul>',
    es: '<p class="center-forgot-text">Para encontrar su número de registro de FEMA:</p><ul><li>Si ha creado una cuenta en línea, vaya a <a href="https://www.disasterassistance.gov/" target="_blank">DisasterAssistance.gov</a>.</li><li>Visite un Centro de Recuperación por Desastre (DRC).<ul><li>Para encontrar un DRC, visite <a href="https://egateway.fema.gov/ESF6/DRCLocator" target="_blank">https://egateway.fema.gov/ESF6/DRCLocator</a>,</li></ul></li><li>Llame a la línea de ayuda de FEMA al <a href="tel:+1-800-621-3362">800-621-3362</a>.<ul><li>Si utiliza un servicio de retransmisión, facilite a FEMA el número de su servicio.</li></ul></li></ul>',
  },
  {
    Code: 'Forgot-RegNumber-Detail-2',
    en: 'If you use a video relay service (VRS), captioned telephone service, or other communication services, give FEMA your number for that service',
    es: 'Si utiliza un servicio de retransmisión de vídeo (VRS), un servicio telefónico con subtítulos u otros servicios de comunicación, facilite a FEMA el número de dicho servicio',
  },
  { Code: 'Steps-Title-1', en: 'Search for a hotel', es: 'Buscar un hotel' },
  {
    Code: 'Steps-Desc-1',
    en: 'Enter your FEMA registration number and then search for a hotel.',
    es: 'Introduzca su número de registro FEMA y busque un hotel.',
  },
  { Code: 'Steps-Title-2', en: 'Call the hotel before you go', es: 'Llame al hotel antes de ir' },
  {
    Code: 'Steps-Desc-2',
    en: 'Ask the hotel if they have availability for TSA. If they do, provide your estimated arrival time. When you arrive at the hotel, be prepared to provide your FEMA registration number, first and last name, last 4 of digits of your social security number, and a valid photo ID.',
    es: 'Pregunte en el hotel si tienen disponibilidad para la TSA. En caso afirmativo, indique su hora prevista de llegada. Cuando llegue al hotel, esté preparado para facilitar su número de registro de la FEMA, nombre y apellidos, los 4 últimos dígitos de su número de la seguridad social y un documento de identidad válido con fotografía.',
  },
  {
    Code: 'Steps-Title-3',
    en: 'FEMA will cover the cost of your room, taxes, and pet fees.',
    es: 'FEMA cubrirá sus gastos de habitación, impuestos y cargos por mascota.',
  },
  { Code: 'Steps-Desc-3', en: 'Expenses will be covered up to the maximum rate allowed. This rate varies by disaster and location.', es: 'Los gastos se cubrirán hasta la tasa máxima permitida. Esta tasa varía en función de la catástrofe y el lugar.' },
  { Code: 'Need-Help', en: 'Need Help?', es: '¿Necesita ayuda?' },
  { Code: 'Next-Steps', en: 'Next Steps', es: 'Próximos Pasos' },
  { Code: 'FAQ001', en: 'What is TSA?', es: '¿Qué es TSA?' },
  {
    Code: 'FAQ001HTML',
    en: '<p>TSA is a short-term, non-congregate sheltering program for displaced households staying in emergency shelter locations such as congregate shelters, vehicles, and places of employment after a disaster. TSA provides temporary emergency sheltering, e.g., hotels and motels for eligible households, as they transition from emergency shelters to temporary or permanent housing solutions.</p>',
    es: '<p>TSA es un programa de alojamiento no colectivo a corto plazo para familias desplazadas que se alojan en ubicaciones de refugios de emergencia como refugios colectivos, vehículos y lugares de trabajo después de un desastre. TSA proporciona alojamiento provisional de emergencia, por ejemplo, hoteles y moteles, para las familias elegibles, mientras pasan de los refugios de emergencia a soluciones de vivienda temporal o permanente.</p>',
  },
  {
    Code: 'FAQ002',
    en: 'How will I be notified of my TSA eligibility?',
    es: '¿Cómo se me informará si soy elegible para TSA?',
  },
  {
    Code: 'FAQ002HTML',
    en: '<p>If you are eligible for TSA, you will be notified through an automated phone call, text message, and/or email depending upon the method of communication you selected at the time you registered for assistance from FEMA.</p>',
    es: '<p>Si usted es elegible para TSA, se le notificará a través de una llamada telefónica automatizada, mensaje de texto o correo electrónico según el método de comunicación que seleccionó cuando solicitó asistencia de FEMA.</p>',
  },
  {
    Code: 'FAQ003',
    en: 'How will a hotel know if I am eligible for TSA?',
    es: '¿Cómo puede saber un hotel si soy elegible para TSA?',
  },
  {
    Code: 'FAQ003HTML',
    en: '<p>When TSA is activated, the hotel will verify my eligibility through FEMA’s authorized lodging contractor’s automated database.</p>',
    es: '<p>Cuando se active TSA, el hotel verificará mi elegibilidad a través de la base de datos automatizada del contratista de alojamiento autorizado de FEMA.</p>',
  },
  { Code: 'FAQ004', en: 'How do I find a hotel room?', es: '¿Cómo encuentro una habitación de hotel?' },
  {
    Code: 'FAQ004HTML',
    en: '<ul><li>You must call the lodging property to confirm they have an available room.</li><li>Online reservations cannot be transitioned to this program and charges placed on eligible survivor personal credit cards, gift cards or charitable organizations method of payment cannot be reversed.</li><li>After you find an available room at a participating hotel, go to the lodging property as soon as possible to finish the check in process.</li></ul><p>You can only check into one participating lodging property at a time.</p>',
    es: '<ul><li>Debe llamar al alojamiento para confirmar que tiene una habitación disponible.</li><li>Las reservaciones en línea no pueden transferirse a este programa y los cargos efectuados en tarjetas de crédito personales del sobreviviente, tarjetas regalo o métodos de pago de organizaciones benéficas no pueden anularse.</li><li>Cuando encuentre una habitación disponible en un hotel participante, diríjase al establecimiento lo antes posible para finalizar el proceso de ingreso.</li></ul><p>Sólo puede registrarse en un alojamiento participante a la vez.</p>',
  },
  {
    Code: 'FAQ005',
    en: 'What information will I need to provide the hotel?',
    es: '¿Qué información debo facilitar al hotel?',
  },
  {
    Code: 'FAQ005HTML',
    en: '<ul><li>Your FEMA registration number</li><li>First and Last name</li><li>Valid government issued photo identification</li><li>Last four digits of your Social Security Number</li></ul><p>FEMA does not require anyone to present a credit card or deposit at the time of check in. <br />You will be required to sign the TSA Terms and Conditions when checking in and checking out.</p><p><b>If you need assistance finding a hotel room, please call FEMA at <a href="tel+1-800-621-3362">1-800-621-3362</a></b></p>',
    es: '<ul><li>Su número de registro de FEMA</li><li>Nombre y apellidos</li><li>Identificacion valida con foto expedida por el gobierno</li><li>Últimos cuatro dígitos de su número de Seguro Social</li></ul><p>FEMA no exige a nadie que presente una tarjeta de crédito o un depósito al momento de ingresar al hotel. <br />Se le pedirá que firme los Términos y Condiciones de  TSA durante el ingreso y salida del hotel.</p><p><b>Si necesita ayuda para encontrar una habitación de hotel, llame a FEMA al <a href="tel:+1-800-621-3362">1-800-621-3362</a></b></p>',
  },
  { Code: 'Menu-Find', en: 'Find a Hotel', es: 'Buscar un hotel' },
  { Code: 'Menu-Help', en: 'Help', es: 'Ayuda' },
  { Code: 'Menu-Log', en: 'Log Out', es: 'Cerrar sesión' },
  {
    Code: 'Refresh-Text',
    en: 'Refresh results to see the latest hotel information.',
    es: 'Actualizar los resultados para ver la información más reciente del hotel.',
  },
  { Code: 'Refresh-Button', en: 'Refresh Now', es: 'Actualizar ahora' },
  { Code: 'Cookie-Policy', en: 'Cookie policy', es: 'Política de cookies' },
  {
    Code: 'Cookie-Policy-Text',
    en: 'FEMA.gov uses single-session cookies to serve technical purposes, like providing seamless navigation through the platform. These cookies do not permanently record data, and they are not stored on your computer’s hard drive. Session cookies are only available during an active browser session. When you close your browser, the session cookie disappears. FEMA.gov also uses multi-session cookies to help us understand how people use the platform, and how we can make it better. We use Google Analytics Web analytics software. We do not collect any personally identifiable information (PII). Traffic statistics are collected anonymously and aggregated, and no information is traceable to any specific individual.',
    es: 'FEMA.gov utiliza cookies de sesión única con fines técnicos, como proporcionar una navegación fluida a través de la plataforma. Estas cookies no registran datos de forma permanente y no se almacenan en el disco duro de su computadora. Las cookies de sesión sólo están disponibles durante una sesión activa del navegador. Al cerrar el navegador, la cookie de sesión desaparece. FEMA.gov también utiliza cookies multisesión para ayudarnos a comprender cómo utilizan los usuarios la plataforma y cómo podemos mejorarla. Utilizamos el software de análisis en línea de Google Analytics. No recopilamos ninguna información de identificación personal. Las estadísticas de tráfico se recopilan de forma anónima y agregada, y no se puede rastrear ninguna información hasta un individuo específico.',
  },
  { Code: 'Need-Help-Link', en: 'Have Questions? Need Help?', es: '¿Tiene alguna pregunta? ¿Necesita ayuda?' },
  {
    Code: 'Registration-Invalid-Tsa',
    en: '<b>Something is wrong. The FEMA registration number you have entered is not linked to the Transitional Sheltering Assistance (TSA) Program.</b>',
    es: '<b>Algo está incorrecto. El número de registro de FEMA que ha introducido no está vinculado al Programa de Alojamiento Transitorio (TSA).</b>',
  },
  {
    Code: 'Registration-Invalid-Tsa-Subtext',
    en: '<div><p><b>Please</b></p><ul><li>Double-check your FEMA registration number and try again.</li><li>Check your communication from FEMA (voice, text, email, online account) to confirm you are eligible for TSA.</li><li>Visit a Disaster Recovery Center (DRC). Use the <a href="https://egateway.fema.gov/ESF6/DRCLocator" target="_blank">DRC Locator</a> or text <b>DRC</b> and your <b>ZIP code</b> to <b>43362</b>.</li><li>Call the FEMA Helpline at <a href="tel:+1-800-621-3662">800-621-3662</a></li><li>If you use a Relay Service, provide that information to FEMA.</li></ul></div>',
    es: '<div><p><b>Por favor,</b></p><ul><li>Vuelva a comprobar su número de registro de FEMA e inténtelo de nuevo.</li><li>Verifique su comunicación de FEMA (voz, texto, correo electrónico, cuenta en línea) para confirmar que usted es elegible para TSA.</li><li>Visite un Centro de Recuperación por Desastre (DRC). Utilice el <a href="https://egateway.fema.gov/ESF6/DRCLocator" target="_blank">localizador de DRC</a> o envíe un mensaje de texto con la palabra <b>CENTRO</b> y su <b>código postal</b> al <b>43362</b>.</li><li>Llame a la línea de ayuda de FEMA al <a href="tel:+1-800-621-3662">800-621-3662</a></li><li>Si utiliza un servicio de retransmisión, facilite esa información a FEMA.</li></ul></div>',
  },
  {
    Code: 'No-Participating-Hotels',
    en: '<b>There are currently no participating hotels with TSA availability.</b>',
    es: '<b>Actualmente no hay hoteles participantes con disponibilidad para TSA.</b>',
  },
  {
    Code: 'Subtext-No-Participating-Hotels',
    en: '<div><ul><li>If you need immediate shelter, text Shelter and your zip code to 43362</li><li>Visit DisasterAssistance.gov or use the FEMA mobile app. </li><li>Visit a Disaster Recovery Center (DRC). To find a DRC go to <a href="https://egateway.fema.gov/ESF6/DRCLocator" target="_blank">https://egateway.fema.gov/ESF6/DRCLocator</a></li><li>Call the FEMA Helpline at <a href="tel:+1-800-621-3662">800-621-3662</a></li></ul></div>',
    es: '<div><ul><li>Si necesita refugio inmediatamente, envíe un mensaje de texto con la palabra REFUGIO y su código postal al 43362</li><li>Visite DisasterAssistance.gov/es o use la aplicación móvil de FEMA</li><li>Visite un Centro de Recuperación por Desastre (DRC). Para encontrar un DRC visite <a href="https://egateway.fema.gov/ESF6/DRCLocator" target="_blank">https://egateway.fema.gov/ESF6/DRCLocator</a></li><li>Llame a la línea de ayuda de FEMA al <a href="tel:+1-800-621-3662">800-621-3362</a></li></ul></div>',
  },
  {
    Code: 'Exception-Error',
    en: 'Something went wrong. Please try again.',
    es: 'Algo está incorrecto. Por favor, inténtelo de nuevo.',
  },

  {
    Code: 'No-Results-Returned',
    en: '<p class="error-overlay-headline">There are no results for your search.</p><div class="error-overlay-content"><p>Try:</p><ul><li>changing your search criteria</li><li>Increase the number of miles in your search criteria</li><li>Clear your filters and try again</li></ul></div>',
    es: '<p class="error-overlay-headline">No hay resultados para su búsqueda.</p><div class="error-overlay-content"><p>Intente:</p><ul><li>cambiar los criterios de su búsqueda</li><li>aumentar la cantidad de millas en su criterio de búsqueda</li><li>borrar los filtros y volver a intentar</li></ul></div>',
  },

  { Code: 'Unlimited', en: 'Unlimited', es: 'Sin límites' },
    {Code:'Expand-Search-Radius', en:'Expand your search radius to {radius} {miles}', es:'Ampliar el radio de búsqueda a {radius} {miles}'}, //Need Translated by client - on list
  { Code: 'Please-Try-Again', en: 'Please try again', es: 'Por favor, inténtelo de nuevo' },
  { Code: 'Kitchenette', en: 'Kitchenette', es: 'Cocina' },
  {
    Code: 'Filter-Pets-Text',
    en: 'Only show hotels that allow pets',
    es: 'Mostrar sólo hoteles que admiten mascotas',
  },
  {
    Code: 'Filter-ADA-Text',
    en: 'Only show hotels with ADA Accommodations (Call to confirm availability)',
    es: 'Mostrar sólo hoteles con adaptaciones de ADA para personas con discapacidades (Llamar para confirmar disponibilidad)',
  },
  {
    Code: 'Filter-Kitchenettes-Text',
    en: 'Only show hotels that have rooms with kitchenettes',
    es: 'Mostrar sólo hoteles que dispongan de habitaciones con cocina',
  },
  { Code: 'Hotel-Name', en: 'Hotel Name', es: 'Nombre del hotel' },
  { Code: 'Search-Hotels', en: 'Search Hotels', es: 'Buscar hoteles' },
  { Code: 'Miles', en: 'miles', es: 'millas' },
  { Code: 'Showing', en: 'Showing', es: 'Mostrando' },
  { Code: 'Show', en: 'Show', es: 'Mostrar' },
  { Code: 'Clear-All', en: 'Clear All', es: 'Borrar todo' },
  { Code: 'Clear-Filters', en: 'Clear filters', es: 'Borrar filtros' },
  { Code: 'Valid-Location', en: 'Please enter a valid location', es: 'Por favor, ingrese una ubicación válida' },
  { Code: 'Of', en: 'of', es: 'de' },
  { Code: 'Language', en: 'Language', es: 'Idioma' },
  { Code: 'Distance', en: 'Distance', es: 'Distancia' },
  { Code: 'Filtered', en: 'filtered', es: 'filtrado' },
  { Code: 'ResultTitle', en: 'Results', es: 'Resultados' },
  {Code:'Expand-Search-Radius-IsGeo', en:'Expand your search radius', es:'Ampliar área de búsqueda'},

];

export function FetchTranslationByCodeAndLanguage(code, language) {
  // console.log('FetchTranslation -', code, "for", language);
    const translation = translations.find(t => t.Code === code);
    return translation ? translation[language] : '';
}

export function FetchTranslationsByLanguage(language) {
 let tempDictionary = [];
 switch (language) {
    case 'en':
      translations.forEach(t => {
        tempDictionary.push({code: t.Code, text: t.en});
      });
      break;
    case 'es':
      translations.forEach(t => {
        tempDictionary.push({code: t.Code, text: t.es});
      });
      break;
    default:
      translations.forEach(t => {
        tempDictionary.push({code: t.Code, text: t.en});
      });
      break;
  }

// console.log('tempdictionart: ',  tempDictionary)
  return tempDictionary;
}
